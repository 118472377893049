//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-552:_9384,_5556,_1232,_3203,_2556,_7936,_5224,_8964;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-552')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-552', "_9384,_5556,_1232,_3203,_2556,_7936,_5224,_8964");
        }
      }catch (ex) {
        console.error(ex);
      }