import shopifyGenericDefaults from '../shopify-generic/config.js';
shopifyGenericDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').columnBreakpoint = 750;
shopifyGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'FacetPanel' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('category');
    } else {
      w.ignoreFields = ['category'];
    }
  }
  return w;
});

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
};
