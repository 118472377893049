
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'grid-item' }, _createElement('div', { 'className': 'card card-product card-product-no-rating color-background-2' }, _createElement('figure', { 'className': 'card-media' }, this.on_sale ? _createElement('div', {
                    'className': 'card-badge',
                    'key': '138'
                }, _createElement('span', { 'className': 'badge badge-sale' }, 'Save ', this.calcDiscount(this.price, this.compare_at_price), '%')) : null, _createElement('a', { 'href': this.url }, _createElement('div', { 'className': 'aspect-ratio' }, _createElement('img', {
                    'src': this.image,
                    'alt': this.title,
                    'width': '200',
                    'height': '200',
                    'loading': 'lazy',
                    'className': 'card-media-image',
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'card-info-wrap' }, _createElement('div', { 'className': 'card-info' }, _createElement('h3', { 'className': 'card-heading typography-body' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('p', { 'className': 'card-sku' }, 'SKU: ', this.sku))), _createElement('div', { 'className': 'card-footer' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price-container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price-regular',
                    'key': '952'
                }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), _createElement('span', { 'className': 'price-item price-item-regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price-sale',
                    'key': '1194'
                }, _createElement('s', { 'className': 'price-item price-item-regular' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-item price-item-sale price-item-last' }, this.formatPrice(this.price))) : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid product-grid grid-2-col grid-3-col-tablet grid-4-col-desktop cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []